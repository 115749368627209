import React from "react";
import * as styles from "./horizontalScroller.module.css";

export default class HorizontalScroller extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollX: 0,
        };
    }

    componentDidMount() {}

    onScroll = (e) => {
        let scroll_pos = e.target.scrollLeft;
        if (scroll_pos !== this.state.scrollX && scroll_pos === 0) {
            this.setState({
                scrollX: 0,
            });
        } else if (scroll_pos > 0 && this.state.scrollX === 0) {
            this.setState({
                scrollX: scroll_pos,
            });
        }
    };
    render() {
        return (
            <div onScroll={this.onScroll} className={`${styles.container} ${this.state.scrollX > 0 ? styles.scrolled : ""}`}>
                {this.props.children}
            </div>
        );
    }
}
