import React from "react";
import axios from "axios";
import OverlayContainer from "./overlayContainer";
import TextArea from "./textArea";
import Button from "./button";
import Input from "./input";
import * as styles from "./collectionOverlay.module.css";
import Api from "../config/api";
import Util from "../config/util";

export default class CollectionOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,

            hasUpdatedName: false,
            hasUpdatedDescription: false,
            hasUpdatedDisplayImage: false,

            name: "",
            description: "",
            scheme: props.scheme,

            uploadedDisplayImageKey: null,
            dataDisplayImage: null,
            base64DisplayImage: null,

            createdCollection: false,
        };
    }

    onSelectDisplayImageFile = () => {
        this.selectDisplayImageFile()
            .then((file) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    console.log(reader);
                    this.setState({
                        hasUpdatedDisplayImage: true,
                        uploadedDisplayImageKey: null,
                        dataDisplayImage: file,
                        base64DisplayImage: reader.result,
                    });
                };
            })
            .catch((e) => {
                Util.notify.error("Invalid file: " + e);
            });
    };
    selectDisplayImageFile = () => {
        return new Promise((resolve, reject) => {
            let input = document.createElement("input");
            input.type = "file";
            input.multiple = false;
            input.accept = "image/*";
            input.onchange = (_) => {
                let files = Array.from(input.files);
                if (files[0].size > 50000000) {
                    reject("File size too big.");
                } else {
                    resolve(files[0]);
                }
            };
            input.click();
        });
    };

    onNameUpdated = (text) => {
        this.setState({
            name: text,
            hasUpdatedName: true,
        });
    };
    onDescriptionUpdated = (text) => {
        this.setState({
            description: text,
            hasUpdatedDescription: true,
        });
    };

    uploadDisplayImage = () => {
        return new Promise((resolve, reject) => {
            if (!this.state.dataDisplayImage) {
                reject();
            } else if (this.state.uploadedDisplayImageKey) {
                resolve(this.state.uploadedDisplayImageKey);
            } else {
                Api.market({
                    endpoint: "/content/upload/url/",
                    method: "GET",
                    data: {
                        contentType: this.state.dataDisplayImage.type.toLowerCase(),
                        dataType: "COLLECTION_DISPLAY_IMAGE",
                    },
                })
                    .then((upload_res) => {
                        axios({
                            method: "PUT",
                            url: upload_res.url,
                            headers: {
                                "Content-Type": this.state.dataDisplayImage.type.toLowerCase(),
                            },
                            data: this.state.dataDisplayImage,
                        })
                            .then(() => {
                                this.setState(
                                    {
                                        uploadedDisplayImageKey: upload_res.key,
                                    },
                                    () => {
                                        this.checkUploadStatus(upload_res.key, 20)
                                            .then(() => {
                                                resolve(upload_res.key);
                                            })
                                            .catch((e) => {
                                                reject();
                                            });
                                    }
                                );
                            })
                            .catch(reject);
                    })
                    .catch(reject);
            }
        });
    };

    checkUploadStatus = (key, maxRetries, retryCount = 0) => {
        return new Promise((resolve, reject) => {
            Api.market({
                endpoint: "/content/upload/status/",
                method: "GET",
                data: {
                    key,
                },
            })
                .then((status_res) => {
                    if (status_res.status !== "UPLOADED") {
                        if (maxRetries > retryCount) {
                            setTimeout(() => {
                                this.checkUploadStatus(key, maxRetries, retryCount + 1)
                                    .then(resolve)
                                    .catch(reject);
                            }, 1000);
                        } else {
                            resolve(false);
                        }
                    } else {
                        resolve(true);
                    }
                })
                .catch(reject);
        });
    };

    onCreate = () => {
        if (!this.state.name || this.state.name.length <= 1) {
            Util.notify.error(`Invalid Collection Name provided. Please check and try again.`);
        } else if (!this.state.description || this.state.description.length <= 1) {
            Util.notify.error(`Invalid Collection Description provided. Please check and try again.`);
        } else if (!this.state.base64DisplayImage || this.state.base64DisplayImage.length <= 1) {
            Util.notify.error(`Invalid Collection Image provided. Please check and try again.`);
        } else {
            this.setState(
                {
                    saving: true,
                },
                () => {
                    this.uploadDisplayImage()
                        .then((display_image_key) => {
                            Api.market({
                                endpoint: "/content/collection",
                                method: "POST",
                                data: {
                                    name: this.state.name,
                                    description: this.state.description,
                                    displayImageKey: display_image_key,
                                    scheme: this.state.scheme,
                                },
                            })
                                .then((res) => {
                                    let newCollectionId = res.collectionId;
                                    this.setState(
                                        {
                                            createdCollection: true,
                                        },
                                        () => {
                                            this.props.onComplete(newCollectionId);
                                        }
                                    );
                                })
                                .catch((e) => {
                                    Util.notify.error(`Failed to create collection, please retry now or try again later.`);
                                    console.error(e);
                                    this.setState({
                                        saving: false,
                                    });
                                });
                        })
                        .catch((e) => {
                            console.error(e);
                            Util.notify.error(`Failed to upload the collection image. Please retry or try again later.`);
                            this.setState({
                                saving: false,
                            });
                        });
                }
            );
        }
    };

    render() {
        return (
            <OverlayContainer header={this.state.createdCollection ? null : "Create a Collection"} onClose={this.props.onClose}>
                {this.state.createdCollection ? (
                    <div className={styles.success_container}>
                        <div className={styles.display_image}>
                            <img src={this.state.base64DisplayImage} />
                        </div>
                        <h1>Collection Created.</h1>
                        <p>Close this window to continue.</p>
                        <br />
                        <br />
                        <Button displayMode={5} hoverMode={6} large={true} text={"Close"} style={{ margin: 0 }} onClick={this.props.onClose} />
                    </div>
                ) : (
                    <div>
                        <TextArea header={"Display Image."}>
                            <div className={styles.display_image_container}>
                                <div className={styles.display_image_left}>
                                    <div className={styles.display_image}>{!!this.state.base64DisplayImage && <img src={this.state.base64DisplayImage} />}</div>
                                </div>
                                <div className={styles.display_image_right}>
                                    <TextArea>
                                        <p>This image should be 500x500 in resolution and can be of filetype: .png, .gif or .jpg</p>
                                        <br />
                                        <Button displayMode={1} hoverMode={6} text={"Select Image"} style={{ margin: 0 }} onClick={this.onSelectDisplayImageFile} />
                                    </TextArea>
                                </div>
                            </div>
                        </TextArea>
                        <TextArea header={"Collection Name."}>
                            <Input placeholder={"Name of your collection"} onTextChanged={this.onNameUpdated} defaultValue={this.state.name} maxLength={50} />
                        </TextArea>
                        <TextArea header={"Collection Description."}>
                            <Input
                                multiline={true}
                                rows={"4"}
                                placeholder={"Description of your collection"}
                                onTextChanged={this.onDescriptionUpdated}
                                defaultValue={this.state.description}
                                maxLength={400}
                            />
                        </TextArea>
                        <TextArea center={true}>
                            <Button
                                displayMode={5}
                                hoverMode={6}
                                disabled={this.state.saving || (!this.state.hasUpdatedName && !this.state.hasUpdatedDescription)}
                                text={this.state.saving ? "Creating..." : "Create Collection"}
                                onClick={this.onCreate}
                            />
                        </TextArea>
                    </div>
                )}
            </OverlayContainer>
        );
    }
}
