import React from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { withAuthentication } from "../../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../../components/main";
import Button from "../../../components/button";
import Input from "../../../components/input";
import ScreenLoad from "../../../components/screenLoad";
import TextArea from "../../../components/textArea";
import DeleteTag from "../../../components/deleteTag";
import GenreSelector from "../../../components/genreSelector";
import NavPanel from "../../../components/navPanel";
import ContentArea from "../../../components/contentArea";
import CollectionBox from "../../../components/collectionBox";
import CollectionOverlay from "../../../components/collectionOverlay";
import ToggleSwitch from "../../../components/toggleSwitch";
import LinkWithIcon from "../../../components/linkWithIcon";
import HorizontalScroller from "../../../components/horizontalScroller";
import SelectionBox from "../../../components/selectionBox";
import MediaStickyPreview from "../../../components/mediaStickyPreview";
import Tile from "../../../components/tile";
import SaveBar from "../../../components/saveBar";
import Consts from "../../../config/consts";
import Common from "../../../config/common";
import Util from "../../../config/util";
import Api from "../../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faLock, faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import bgroovia_tile_icon_500 from "../../../images/bgroovia_tile_icon_500.png";
import bminted_tile_icon_500 from "../../../images/bminted_tile_icon_500.png";
const maxSizeGif = 5000000
const maxSizeRegular = 100000000
export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class MediaCreate extends React.Component {
        percent_timer = null;
        constructor(props) {
            super(props);
            const params = new URLSearchParams(props.location.search);
            this.state = {
                initialising: true,
                saving: false,
                requiresSigning: props.auth.user.requiresSigning === true,
                fullscreen: false,

                // Passed through params - these can be null
                param_scheme: params.get("scheme"),
                param_collectionId: params.get("collectionId"),

                hasUpdatedData: false,
                hasUpdatedDisplayImage: false,
                hasUpdatedName: false,
                hasUpdatedDescription: false,
                hasUpdatedGenres: false,

                name: "",
                description: "",
                tags: [],
                genres: [],
                newTag: "",
                base64Data: null,
                dataType: null,
                base64DisplayImage: null,
                alternateText: "",

                schemes: props.auth.user.profiles.map((p) => p.scheme),
                chosenProfile: props.auth.user.profiles[0],
                chosenScheme: props.auth.user.profiles[0].scheme,

                partOfCollection: false,
                chosenCollection: null,
                allCollections: [],
                schemeCollections: [],

                hasHiddenContent: false,
                hiddenContent: "",

                hasAttributes: false,
                attributes: [{ key: "", value: "" }],

                dataFile: null,
                dataDisplayImage: null,
                uploadedDataFileKey: null,
                uploadedDisplayImageKey: null,
                percentageComplete: null,
                draggedOver: false,

                showCollectionOverlay: false,

                mediaDataKey: null,
                displayImageKey: null,
            };

            // Overwrite some values based on params being passed through
            if (this.state.param_scheme) {
                // We've been given a scheme to use - check that we have that scheme first
                if (this.state.schemes.indexOf(this.state.param_scheme) > -1) {
                    this.state.chosenScheme = this.state.param_scheme;
                }
            }
        }

        componentDidMount() {
            if (Common.objectPropertyExists(this.props.auth, "user.type", "VIEWER") !== "CREATOR") {
                navigate(`/`);
            } else {
                Api.market({
                    endpoint: "/content/collection/list",
                    method: "GET",
                })
                    .then((res) => {
                        let chosenCollection = null;

                        // Check if we were passed through a collection to pre-select
                        if (this.state.param_collectionId) {
                            // Check that we've got this collection and its owned by the current scheme
                            const collection_match = res.data.filter((c) => c.collectionId === this.state.param_collectionId && c.scheme === this.state.param_scheme);
                            if (collection_match.length > 0) {
                                chosenCollection = collection_match[0];
                            }
                        }

                        this.setState({
                            initialising: false,
                            allCollections: res.data,
                            schemeCollections: this.state.chosenScheme ? res.data.filter((c) => c.scheme === this.state.chosenScheme) : [],
                            chosenCollection,
                            partOfCollection: chosenCollection ? true : false,
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            initialising: false,
                            allCollections: [],
                            schemeCollections: [],
                        });
                    });
            }
        }

        onToggleFullScreen = () => {
            this.setState({
                fullscreen: !this.state.fullscreen,
            });
        };

        onSelectDataFile = () => {
            this.selectDataFile()
                .then((file) => {
                    this.processFile(file);
                })
                .catch((e) => {
                    Util.notify.error("Invalid file: " + e);
                });
        };
        selectDataFile = () => {
            return new Promise((resolve, reject) => {
                let input = document.createElement("input");
                input.type = "file";
                input.multiple = false;
                input.accept = "image/png, image/jpeg, image/gif, image/svg, audio/wave, audio/mp3, video/mp4";
                input.onchange = (_) => {
                    let files = Array.from(input.files);
                    const {type,size} = files[0]
                    if(type === 'image/gif' && size > maxSizeGif){
                        reject("File gif size too big.");
                    } else if (size> maxSizeRegular) {
                        reject("File size too big.");
                    } else {
                        resolve(files[0]);
                    }
                };
                input.click();
            });
        };
        processFile = (file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                // console.log("--- Content-type1", file)
                // let contentType = file.type == "image/gif" ? file.type : file
                // console.log("--- Content-type2", contentType)
                let data_start = reader.result.toString().toLowerCase().substring(0, reader.result.toString().indexOf(";"));
                this.setState({
                    hasUpdatedData: true,
                    uploadedDataFileKey: null,
                    dataFile: file,
                    base64Data: reader.result,
                    base64DisplayImage: data_start.indexOf("image") > -1 ? reader.result : null,
                    dataType: data_start.indexOf("audio") > -1 ? "AUDIO" : data_start.indexOf("video") > -1 ? "VIDEO" : "IMAGE",
                });
            };
        };
        onDropFile = (event) => {
            event.preventDefault();
            if (event.dataTransfer.items) {
                for (let i = 0; i < event.dataTransfer.items.length; i++) {
                    if (event.dataTransfer.items[i].kind === "file") {
                        const file = event.dataTransfer.items[i].getAsFile();
                        this.processFile(file);
                        break;
                    }
                }
            } else {
                for (let i = 0; i < event.dataTransfer.files.length; i++) {
                    this.processFile(event.dataTransfer.files[i]);
                    break;
                }
            }
        };
        onDragOver = (event) => {
            event.preventDefault();
            if (this.state.draggedOver === false) {
                this.setState({
                    draggedOver: true,
                });
            }
        };
        onDragLeave = (event) => {
            event.preventDefault();
            if (this.state.draggedOver === true) {
                this.setState({
                    draggedOver: false,
                });
            }
        };

        onSelectDisplayImageFile = () => {
            this.selectDisplayImageFile()
                .then((file) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        console.log(reader);
                        this.setState({
                            hasUpdatedDisplayImage: true,
                            uploadedDisplayImageKey: null,
                            dataDisplayImage: file,
                            base64DisplayImage: reader.result,
                        });
                    };
                })
                .catch((e) => {
                    Util.notify.error("Invalid file: " + e);
                });
        };
        selectDisplayImageFile = () => {
            return new Promise((resolve, reject) => {
                let input = document.createElement("input");
                input.type = "file";
                input.multiple = false;
                input.accept = "image/*";
                input.onchange = (_) => {
                    let files = Array.from(input.files);
                    if (files[0].size > 50000000) {
                        reject("File size too big.");
                    } else {
                        resolve(files[0]);
                    }
                };
                input.click();
            });
        };

        onTileNameClick = () => {
            // TODO: Focus on the name field
        };

        onNameUpdated = (text) => {
            this.setState({
                name: text,
                hasUpdatedName: true,
            });
        };
        onDescriptionUpdated = (text) => {
            this.setState({
                description: text,
                hasUpdatedDescription: true,
            });
        };
        onAlternateTextUpdated = (text) => {
            this.setState({
                alternateText: text,
                hasUpdatedAlternateText: true,
            });
        };

        onRemoveContent = () => {
            this.setState({
                content: null,
            });
        };

        uploadData = () => {
            // console.log("--- self.state.dataFile.type", self.state.dataFile.type)
            // return
            return new Promise((resolve, reject) => {
                if (!this.state.dataFile) {
                    reject();
                } else if (this.state.uploadedDataFileKey) {
                    resolve(this.state.uploadedDataFileKey);
                } else {
                    Api.market({
                        endpoint: "/content/upload/url/",
                        method: "GET",
                        data: {
                            contentType: this.state.dataFile.type.toLowerCase(),
                            dataType: "MEDIA_DATA",
                        },
                    })
                        .then((upload_res) => {
                            console.log("--- /content/upload/url/ GET response", upload_res)
                            console.log("--- Our picked datafile", this.state.dataFile)
                            axios({
                                method: "PUT",
                                url: upload_res.url,
                                headers: {
                                    "Content-Type": this.state.dataFile.type.toLowerCase(),
                                },
                                data: this.state.dataFile,
                            })
                                .then((res) => {
                                    console.log("--- ", res)
                                    this.setState(
                                        {
                                            uploadedDataFileKey: upload_res.key,
                                            percentageComplete: 20,
                                        },
                                        () => {
                                            this.checkUploadStatus(upload_res.key, 20)
                                                .then(() => {
                                                    resolve(upload_res.key);
                                                })
                                                .catch((e) => {
                                                    reject();
                                                });
                                        }
                                    );
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                }
            });
        };

        uploadDisplayImage = () => {
            return new Promise((resolve, reject) => {
                if (this.state.dataType === "IMAGE") {
                    // We don't need to upload the display image for an image media
                    // as it'll be the same as the data
                    resolve();
                } else if (!this.state.dataDisplayImage) {
                    reject();
                } else if (this.state.uploadedDisplayImageKey) {
                    resolve(this.state.uploadedDisplayImageKey);
                } else {
                    Api.market({
                        endpoint: "/content/upload/url/",
                        method: "GET",
                        data: {
                            contentType: this.state.dataDisplayImage.type.toLowerCase(),
                            dataType: "MEDIA_DISPLAY_IMAGE",
                        },
                    })
                        .then((upload_res) => {
                            axios({
                                method: "PUT",
                                url: upload_res.url,
                                headers: {
                                    "Content-Type": this.state.dataDisplayImage.type.toLowerCase(),
                                },
                                data: this.state.dataDisplayImage,
                            })
                                .then(() => {
                                    this.setState(
                                        {
                                            uploadedDisplayImageKey: upload_res.key,
                                            percentageComplete: 40,
                                        },
                                        () => {
                                            this.checkUploadStatus(upload_res.key, 20)
                                                .then(() => {
                                                    resolve(upload_res.key);
                                                })
                                                .catch((e) => {
                                                    reject();
                                                });
                                        }
                                    );
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                }
            });
        };

        checkUploadStatus = (key, maxRetries, retryCount = 0) => {
            return new Promise((resolve, reject) => {
                Api.market({
                    endpoint: "/content/upload/status/",
                    method: "GET",
                    data: {
                        key,
                    },
                })
                    .then((status_res) => {
                        if (status_res.status === "FAIlED") {
                            reject();
                        } else if (status_res.status !== "UPLOADED") {
                            if (maxRetries > retryCount) {
                                setTimeout(() => {
                                    this.checkUploadStatus(key, maxRetries, retryCount + 1)
                                        .then(resolve)
                                        .catch(reject);
                                }, 1000);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(true);
                        }
                    })
                    .catch(reject);
            });
        };

        validate = () => {
            if (!this.state.name || this.state.name.length <= 1) {
                return `Invalid Name provided. Please check and try again.`;
            } else if (!this.state.description || this.state.description.length <= 1) {
                return `Invalid Description provided. Please check and try again.`;
            } else if (!this.state.base64Data || this.state.base64Data.length <= 1) {
                return `Invalid Media Data provided. Please check and try again.`;
            } else if (!this.state.base64DisplayImage || this.state.base64DisplayImage.length <= 1) {
                return `Invalid Display Image provided. Please check and try again.`;
            } else if (!this.state.tags || this.state.tags.length <= 0) {
                return `Missing Tags. Please enter at least 1 tag.`;
            } else if (!this.state.genres || this.state.genres.length <= 0) {
                return `Missing Genres. Please select at least 1 Genre.`;
            } else if (this.state.partOfCollection && this.state.chosenCollection === null) {
                return `Marked as being part of a collection but no collection selected.`;
            } else if (this.state.schemes.length > 1 && this.state.chosenScheme === null) {
                return `Missing scheme chosen.`;
            } else {
                return "";
            }
        };

        onCreate = () => {
            const validate_res = this.validate();
            if (validate_res !== "") {
                Util.notify.error(validate_res);
            } else {
                this.setState(
                    {
                        saving: true,
                    },
                    () => {
                        // First lets upload the media and the display image as this is likely
                        // the place things will go wrong
                        this.clearPercent().finally(() => {
                            this.incrementPercent();
                            Promise.all([this.uploadData(), this.uploadDisplayImage()])
                                .then((upload_res) => {
                                    console.log("--- upload_res", upload_res)
                                    const data = {
                                        scheme: this.state.schemes.length === 1 ? this.state.schemes[0] : this.state.chosenScheme,
                                        name: this.state.name,
                                        description: this.state.description,
                                        tags: this.state.tags,
                                        genres: this.state.genres,
                                        alternateText: this.state.alternateText,
                                        dataKey: upload_res[0],
                                        displayImageKey: this.state.dataType === "IMAGE" ? upload_res[0] : upload_res[1],
                                        ...(this.state.partOfCollection && this.state.chosenCollection && { collectionId: this.state.chosenCollection.collectionId }),
                                        ...(this.state.hasAttributes &&
                                            this.state.attributes.length > 0 && {
                                                attributes: JSON.stringify(
                                                    this.state.attributes.filter((a) => {
                                                        return a.key !== "" && a.value !== "";
                                                    })
                                                ),
                                            }),
                                        ...(this.state.hasHiddenContent && { hiddenContent: this.state.hiddenContent }),
                                    }
                                    console.log("--- data", data)
                                    this.jumpPercent(60).finally(() => {
                                        Api.market({
                                            endpoint: "/content/media",
                                            method: "POST",
                                            data: data,
                                        })
                                            .then((res) => {
                                                console.log("--- res", res)
                                                this.jumpPercent(100).finally(() => {
                                                    setTimeout(() => {
                                                        navigate(`/m/${res.mediaId}`);
                                                    }, 500);
                                                });
                                            })
                                            .catch((e) => {
                                                clearTimeout(this.percent_timer);
                                                Util.notify.error(`Failed to create media, please retry now or try again later.`);
                                                console.error(e);
                                                this.setState({
                                                    saving: false,
                                                });
                                            });
                                    });
                                })
                                .catch((e) => {
                                    clearTimeout(this.percent_timer);
                                    if (e && e.response && e.response.status === 403) {
                                        console.error(e.response.data);
                                        Util.notify.error(
                                            `It looks like you don't have permission to create media at this time! Please check your account verification is complete before continuing.`
                                        );
                                    } else if (e && e.response) {
                                        console.error(e.response.data); // => the response payload
                                    }
                                    Util.notify.error(`Failed to upload the media data. Please retry or try again later.`);
                                    this.setState({
                                        saving: false,
                                    });
                                });
                        });
                    }
                );
            }
        };

        incrementPercent = () => {
            this.percent_timer = setTimeout(() => {
                this.setState(
                    {
                        percentageComplete: Math.round(this.state.percentageComplete + 0.5),
                    },
                    () => {
                        this.incrementPercent();
                    }
                );
            }, 1000);
        };
        jumpPercent = (complete) => {
            return new Promise((resolve, reject) => {
                this.setState(
                    {
                        percentageComplete: Math.round(complete),
                    },
                    () => {
                        console.log("Percentage Jumped", this.state.percentageComplete);
                        resolve();
                    }
                );
            });
        };
        clearPercent = () => {
            return new Promise((resolve, reject) => {
                clearTimeout(this.percent_timer);
                this.setState(
                    {
                        percentageComplete: 0,
                    },
                    () => {
                        console.log("Percentage Cleared", this.state.percentageComplete);
                        resolve();
                    }
                );
            });
        };

        onAddTagTextChanged = (text) => {
            this.setState(
                {
                    newTag: text,
                },
                () => {
                    if (/[^0-9a-zA-Z]/.test(this.state.newTag)) {
                        this.onAddTags(this.state.newTag);
                    }
                }
            );
        };
        onAddTags = (tag) => {
            let newTags = tag.replace(/[^0-9a-zA-Z]/g, "_").split("_");
            let addTags = [];
            for (let i = 0; i < newTags.length; i++) {
                try {
                    let t = newTags[i].trim().toLowerCase();
                    if (t.length > 2 && this.state.tags.indexOf(t) === -1) {
                        addTags.push(t);
                    }
                } catch (e) {}
            }
            this.setState({
                tags: [...this.state.tags, ...addTags],
                newTag: "",
            });
        };

        onRemoveTag = (tag) => {
            if (this.state.tags.indexOf(tag) > -1) {
                this.setState({
                    tags: this.state.tags.filter((t) => {
                        return t !== tag;
                    }),
                });
            }
        };

        onGenresChanged = (genres) => {
            this.setState({
                hasUpdatedGenres: true,
                genres,
            });
        };

        onPropertyKeyChange = (text, index) => {
            this.setState(
                {
                    attributes: [
                        ...this.state.attributes.map((p, i) => {
                            if (i === index) {
                                return {
                                    key: text,
                                    value: p.value,
                                };
                            }
                            return p;
                        }),
                    ],
                },
                () => {
                    // Check if we have an empty row - if not lets add one in
                    if (this.state.attributes.filter((p) => p.key === "" && p.value === "").length <= 0) {
                        this.setState({
                            attributes: [...this.state.attributes, ...[{ key: "", value: "" }]],
                        });
                    }
                }
            );
        };
        onPropertyValueChange = (text, index) => {
            this.setState(
                {
                    attributes: [
                        ...this.state.attributes.map((p, i) => {
                            if (i === index) {
                                return {
                                    key: p.key,
                                    value: text,
                                };
                            }
                            return p;
                        }),
                    ],
                },
                () => {
                    // Check if we have an empty row - if not lets add one in
                    if (this.state.attributes.filter((p) => p.key === "" && p.value === "").length <= 0) {
                        this.setState({
                            attributes: [...this.state.attributes, ...[{ key: "", value: "" }]],
                        });
                    }
                }
            );
        };
        onDeleteProperty = (index) => {
            if (this.state.attributes.length <= 1) {
                this.setState({
                    attributes: [{ key: "", value: "" }],
                });
            } else {
                this.setState({
                    attributes: this.state.attributes.filter((p, i) => {
                        return i !== index;
                    }),
                });
            }
        };

        selectProfile = (profile) => {
            const newScheme = profile.scheme;
            const newSchemeCollections = this.state.allCollections.filter((c) => c.scheme === profile.scheme);
            const newchosenCollection =
                this.state.chosenCollection !== null && newSchemeCollections.filter((c) => c.collectionId === this.state.chosenCollection.collectionId).length > 0 ? this.state.chosenCollection : null;

            this.setState({
                chosenProfile: profile,
                chosenScheme: newScheme,
                schemeCollections: newSchemeCollections,
                chosenCollection: newchosenCollection,
            });
        };

        onCollectionSwitch = (id, switchedOn) => {
            this.setState({
                partOfCollection: switchedOn,
            });
        };
        onSelectCollection = (collection) => {
            this.setState({
                chosenCollection: collection,
            });
        };

        onAttributeSwitch = (id, switchedOn) => {
            this.setState({
                hasAttributes: switchedOn,
            });
        };

        onHiddenContentSwitch = (id, switchedOn) => {
            this.setState({
                hasHiddenContent: switchedOn,
            });
        };

        onHiddenContentChanged = (text) => {
            this.setState({
                hiddenContent: text,
            });
        };

        onHideOverlays = () => {
            this.setState({
                showCollectionOverlay: false,
            });
        };
        onShowCollectionOverlay = () => {
            this.setState({
                showCollectionOverlay: true,
            });
        };
        onCollectionCreated = (newCollectionId) => {
            Util.notify.info("New Collection Created.");
            Api.market({
                endpoint: "/content/collection/list",
                method: "GET",
            })
                .then((res) => {
                    this.setState(
                        {
                            allCollections: res.data,
                            schemeCollections: this.state.chosenScheme ? res.data.filter((c) => c.scheme === this.state.chosenScheme) : [],
                        },
                        () => {
                            for (let i = 0; i < this.state.schemeCollections.length; i++) {
                                if (this.state.schemeCollections[i].collectionId === newCollectionId) {
                                    this.setState({
                                        chosenCollection: this.state.schemeCollections[i],
                                    });
                                    break;
                                }
                            }
                        }
                    );
                })
                .catch((e) => {
                    this.setState({
                        allCollections: [],
                        schemeCollections: [],
                    });
                });
        };

        onAutoPopulateCollectionAttributes = () => {
            if (this.state.partOfCollection === true && this.state.chosenCollection) {
                this.setState({
                    hasAttributes: true,
                    attributes: [
                        ...this.state.attributes,
                        ...(this.state.chosenCollection.attributes || []).map((attributeKey) => {
                            return {
                                key: attributeKey,
                                value: "",
                            };
                        }),
                    ],
                });
            }
        };

        render() {
            return this.state.fullscreen === true && this.state.base64DisplayImage && this.state.dataType === "IMAGE" ? (
                <main>
                    <div className={styles.fullscreen_container} onClick={this.onToggleFullScreen}>
                        <img src={this.state.base64DisplayImage} />
                    </div>
                </main>
            ) : (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    noFooterMargin={true}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea extraTopPadding={true} centered={true} slim={true}>
                        <ContentArea bottomRule={this.state.dataFile ? true : false} header={"Content."}>
                            {this.state.dataFile ? (
                                <div className={styles.uploadedContainer}>
                                    {this.state.base64DisplayImage && (
                                        <div className={styles.column_1}>
                                            <TextArea header={"Preview."}>
                                                <Tile
                                                    fixedAspect={false}
                                                    highlight={true}
                                                    content={{
                                                        type: this.state.dataType,
                                                        displayImageBase64: this.state.base64DisplayImage,
                                                        creator: {
                                                            displayName: this.state.chosenProfile ? this.state.chosenProfile.displayName : "Pick A Scheme",
                                                            profileImage: this.state.chosenProfile ? this.state.chosenProfile.profileImage : "",
                                                            type: this.state.chosenScheme,
                                                        },
                                                        name: this.state.name.length > 0 ? this.state.name : "Enter a Name below.",
                                                        chainId: null,
                                                        scheme: this.state.chosenScheme,
                                                    }}
                                                    showContent={true}
                                                    showDetail={true}
                                                    hasMarginLeft={false}
                                                    hasMarginRight={false}
                                                    openLinksInNewTab={true}
                                                    displayOnly={true}
                                                    containerStyle={{
                                                        maxWidth: 350,
                                                        minWidth: 250,
                                                        margin: 0,
                                                    }}
                                                />
                                                <br />
                                            </TextArea>
                                        </div>
                                    )}
                                    <div className={styles.column_2}>
                                        <TextArea header={"File Info."}>
                                            {this.state.dataType === "VIDEO" ? (
                                                <div className={styles.media_container}>
                                                    <video controls autoPlay={true} controlsList="nodownload nofullscreen noremoteplayback noplaybackrate" loop={true} preload="true">
                                                        <source src={this.state.base64Data} type="video/mp4" />
                                                        Sorry, your browser doesn't support embedded videos.
                                                    </video>
                                                </div>
                                            ) : (
                                                this.state.dataType === "AUDIO" && (
                                                    <div className={styles.media_container}>
                                                        <audio controls controlsList="nodownload" loop={true} preload="true" src={this.state.base64Data}>
                                                            Your browser does not support the
                                                            <code>audio</code> element.
                                                        </audio>
                                                    </div>
                                                )
                                            )}
                                            <p>
                                                Filename: <b>{this.state.dataFile.name}</b>
                                                <br />
                                                Size: <b>{(this.state.dataFile.size / 1000000).toFixed(2)} MB</b>
                                                <br />
                                            </p>
                                            <Button displayMode={6} hoverMode={3} text={"Change File"} style={{ margin: 0 }} onClick={this.onSelectDataFile} />
                                        </TextArea>
                                        <br />
                                        {this.state.dataType !== "IMAGE" && (
                                            <TextArea header={"Cover Image."}>
                                                {this.state.dataDisplayImage ? (
                                                    <p>
                                                        Filename: <b>{this.state.dataDisplayImage.name}</b>
                                                        <br />
                                                        Size: <b>{(this.state.dataDisplayImage.size / 1000000).toFixed(2)} MB</b>
                                                        <br />
                                                    </p>
                                                ) : (
                                                    <p>
                                                        Due to your media not being of an image type we also require a cover image that we can display instead of showing/playing your actual media.{" "}
                                                        <b>Please select a Cover Image.</b>
                                                        <br />
                                                    </p>
                                                )}
                                                <Button
                                                    displayMode={this.state.dataDisplayImage ? 6 : 5}
                                                    hoverMode={this.state.dataDisplayImage ? 3 : 6}
                                                    text={this.state.dataDisplayImage ? "Change Cover Image" : "Select Cover Image"}
                                                    style={{ margin: 0 }}
                                                    onClick={this.onSelectDisplayImageFile}
                                                />
                                            </TextArea>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={`${styles.dragDropContainer} ${this.state.draggedOver ? styles.hover : ""}`}
                                    onClick={this.onSelectDataFile}
                                    onDrop={this.onDropFile}
                                    onDragOver={this.onDragOver}
                                    onDragLeave={this.onDragLeave}
                                >
                                    <p className={styles.title}>Drag and Drop Here</p>
                                    <p className={styles.description}>
                                        Click here to open or drag your media file here (.png, .jpg, .jpeg, .gif, .svg, .wav, .mp3 or .mp4).
                                        <br />
                                        Max 100MB Filesize <br/>and Max 5MB FileGifsize
                                    </p>
                                </div>
                            )}
                            <br />
                        </ContentArea>
                        {this.state.schemes.length > 1 && (
                            <ContentArea bottomRule={true} header={"Profile."}>
                                <TextArea header={"Associate."}>
                                    <p>Which of your profiles would you like this media to be associated with?</p>
                                    <HorizontalScroller>
                                        {this.props.auth.user.profiles.map((p, index) =>
                                            p.scheme === "BGROOVIA" ? (
                                                <SelectionBox
                                                    key={`profile_${index}`}
                                                    text={p.displayName}
                                                    subtext={"B Groovia"}
                                                    image={bgroovia_tile_icon_500}
                                                    backgroundImage={p.profileImage ? `${process.env.GATSBY_STORAGE}user/${p.profileImage}` : null}
                                                    selected={this.state.chosenScheme === p.scheme}
                                                    onSelected={() => {
                                                        this.selectProfile(p);
                                                    }}
                                                />
                                            ) : p.scheme === "BMINTED" ? (
                                                <SelectionBox
                                                    key={`profile_${index}`}
                                                    text={p.displayName}
                                                    subtext={"B Minted"}
                                                    image={bminted_tile_icon_500}
                                                    backgroundImage={p.profileImage ? `${process.env.GATSBY_STORAGE}user/${p.profileImage}` : null}
                                                    selected={this.state.chosenScheme === p.scheme}
                                                    onSelected={() => {
                                                        this.selectProfile(p);
                                                    }}
                                                />
                                            ) : null
                                        )}
                                    </HorizontalScroller>
                                </TextArea>
                                <br />
                            </ContentArea>
                        )}

                        <ContentArea bottomRule={true} header={"Details."}>
                            <TextArea header={"Name."}>
                                <p>What should we call this media? This name is publicly displayed and will ultimately be used as your NFT name when/if your media is minted.</p>
                                <Input placeholder={"Name your media"} onTextChanged={this.onNameUpdated} defaultValue={this.state.name} maxLength={50} />
                            </TextArea>
                            <br />
                            <TextArea header={"Description."}>
                                <p>
                                    Media description is publicly displayed on your media page and will be stored against your NFT in metadata. Line breaks are allowed but multiple will be cropped to
                                    1.
                                </p>
                                <Input multiline={true} placeholder={"Describe your media"} onTextChanged={this.onDescriptionUpdated} defaultValue={this.state.description} maxLength={400} />
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Collections."} bottomRule={true}>
                            <TextArea header={"Select A Collection."}>
                                <ToggleSwitch switch={this.state.partOfCollection} onSwitch={this.onCollectionSwitch} text={"Is your media part of a collection?"} />
                                {this.state.partOfCollection && (
                                    <div className={styles.full_width_container}>
                                        {this.state.schemeCollections.length > 0 ? (
                                            <HorizontalScroller>
                                                {this.state.schemeCollections.map((collection, index) => (
                                                    <CollectionBox
                                                        key={`collection_${index}`}
                                                        id={collection}
                                                        name={collection.name}
                                                        image={`${process.env.GATSBY_STORAGE}collection/${collection.displayImage}`}
                                                        selected={this.state.chosenCollection && this.state.chosenCollection.collectionId === collection.collectionId}
                                                        onSelected={this.onSelectCollection}
                                                    />
                                                ))}
                                            </HorizontalScroller>
                                        ) : (
                                            <p>
                                                <br />
                                                No Collections have been created yet for your{" "}
                                                {this.state.chosenScheme === "BGROOVIA" ? "B Groovia" : this.state.chosenScheme === "BMINTED" ? "B Minted" : "chosen"} profile - click 'Create New
                                                Collection' below to get started with this profiles first collection!
                                            </p>
                                        )}
                                        <br />
                                        <div className={styles.collection_action_container}>
                                            <LinkWithIcon icon={faPlus} text={"Create New Collection"} onClick={this.onShowCollectionOverlay} />
                                            {/* {this.state.chosenCollection && this.state.chosenCollection.contentCount > 0 && (
                                                <LinkWithIcon icon={faMagicWandSparkles} text={"Auto Populate Common Attributes"} onClick={this.onAutoPopulateCollectionAttributes} />
                                            )} */}
                                        </div>
                                        {this.state.chosenCollection && (
                                            <p>
                                                Adding to Collection:<b>&nbsp;{this.state.chosenCollection.name}</b>
                                                <br />
                                                Active Media Count:<b>&nbsp;{this.state.chosenCollection.contentCount}</b>
                                                <br />
                                            </p>
                                        )}
                                    </div>
                                )}
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Metadata."} bottomRule={true}>
                            <TextArea header={"Attributes."}>
                                <ToggleSwitch switch={this.state.hasAttributes} onSwitch={this.onAttributeSwitch} text={"Does your media have attributes?"} />
                                {this.state.hasAttributes && (
                                    <div className={styles.full_width_container}>
                                        {this.state.attributes.map((prop, index) => (
                                            <div className={styles.property_container} key={`property_${index}`}>
                                                <span
                                                    className={styles.delete_button}
                                                    onClick={() => {
                                                        this.onDeleteProperty(index);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </span>
                                                <Input
                                                    placeholder={"Key"}
                                                    onTextChanged={(text) => {
                                                        this.onPropertyKeyChange(text, index);
                                                    }}
                                                    defaultValue={prop.key}
                                                    maxLength={50}
                                                    hideMaxLength={true}
                                                />
                                                <Input
                                                    placeholder={"Value"}
                                                    onTextChanged={(text) => {
                                                        this.onPropertyValueChange(text, index);
                                                    }}
                                                    defaultValue={prop.value}
                                                    maxLength={50}
                                                    hideMaxLength={true}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </TextArea>
                            <br />
                            <TextArea header={"Hidden Content."}>
                                <ToggleSwitch switch={this.state.hasHiddenContent} onSwitch={this.onHiddenContentSwitch} text={"Does your media have hidden Content?"} />
                                {this.state.hasHiddenContent && (
                                    <div className={styles.full_width_container}>
                                        <p>
                                            Hidden content will only ever be visible by the current owner of the media. This can be useful to pass over information relating to the media that you don't
                                            want to be made public, such as private codes or links to full res images.
                                        </p>
                                        <Input
                                            icon={faLock}
                                            placeholder={"Hidden Content"}
                                            onTextChanged={this.onHiddenContentChanged}
                                            defaultValue={this.state.hiddenContent}
                                            maxLength={500}
                                            hideMaxLength={false}
                                        />
                                    </div>
                                )}
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea bottomRule={false} header={"Search Optimization."}>
                            <TextArea header={"Associate With Genres."}>
                                <p>
                                    We want to make sure that we categorise your media as you'd intended, please select <b>at least 1</b> of the genres below:
                                </p>
                                <GenreSelector selectedGenres={this.state.genres} selectedGenresChanged={this.onGenresChanged} />
                            </TextArea>
                            <br />
                            <TextArea header={"Add Search Tags."}>
                                <p>
                                    Tags are a collection of individual words that describe your media and make it easier to find through searching. A tag can only contain A to Z or 0 to 9 characters
                                    and be no smaller than 2 characters in length. Please enter <b>at least 1</b> tag.
                                </p>
                                <div className={styles.tag_container}>
                                    {this.state.tags.length <= 0 ? (
                                        <p>
                                            <i>No Tags Added Yet. Enter a word and hit the 'Enter' key to submit, or type a 'space' character after your tag to submit.</i>
                                        </p>
                                    ) : (
                                        this.state.tags.map((tag, index) => (
                                            <DeleteTag
                                                key={`delete_tag_${index}`}
                                                deleting={false}
                                                onDelete={() => {
                                                    this.onRemoveTag(tag);
                                                }}
                                                text={tag}
                                            />
                                        ))
                                    )}
                                </div>
                                <Input
                                    disabled={this.state.tags.length >= 10}
                                    placeholder={"Enter a new tag here..."}
                                    onTextChanged={this.onAddTagTextChanged}
                                    onSubmit={this.onAddTags}
                                    defaultValue={this.state.newTag}
                                    maxLength={20}
                                />
                            </TextArea>
                            <br />
                        </ContentArea>
                        <br />
                        <br />
                    </ContentArea>

                    <MediaStickyPreview
                        media={{
                            type: this.state.dataType,
                            displayImageBase64: this.state.base64DisplayImage,
                            creator: {
                                displayName: this.state.chosenProfile ? this.state.chosenProfile.displayName : "Pick A Scheme",
                                profileImage: this.state.chosenProfile ? this.state.chosenProfile.profileImage : "",
                                type: this.state.chosenScheme,
                            },
                            name: this.state.name.length > 0 ? this.state.name : "\\\\ Enter a Name...",
                            chainId: null,
                            scheme: this.state.chosenScheme,
                        }}
                    />

                    <NavPanel showScrollTop={true} extraBottomMargin={true} />

                    <SaveBar
                        disabled={this.state.saving || this.validate() !== ""}
                        text={this.state.saving ? "Creating..." : "Create Media"}
                        percentageComplete={this.state.percentageComplete}
                        onSave={this.onCreate}
                    />

                    {this.state.showCollectionOverlay && <CollectionOverlay scheme={this.state.chosenScheme} onClose={this.onHideOverlays} onComplete={this.onCollectionCreated} />}
                </Main>
            );
        }
    }
);
