// extracted by mini-css-extract-plugin
export var collection_action_container = "index-module--collection_action_container--rxyzq";
export var column_1 = "index-module--column_1--KMg3C";
export var column_2 = "index-module--column_2--eoKR7";
export var data_container = "index-module--data_container--QsVnP";
export var data_container_left = "index-module--data_container_left--8YcbU";
export var data_container_right = "index-module--data_container_right--9O3Os";
export var data_inner_button = "index-module--data_inner_button--AK5R+";
export var data_inner_container = "index-module--data_inner_container--uLIlw";
export var data_inner_no_item = "index-module--data_inner_no_item--I6kyw";
export var data_outer_container = "index-module--data_outer_container--JfYIZ";
export var data_right = "index-module--data_right--vejuJ";
export var delete_button = "index-module--delete_button--N-AVj";
export var description = "index-module--description--z4fff";
export var dragDropContainer = "index-module--dragDropContainer--HJdkW";
export var full_width_container = "index-module--full_width_container--PGQPJ";
export var fullscreen_container = "index-module--fullscreen_container--6VkEY";
export var hover = "index-module--hover--Xqlkn";
export var media_container = "index-module--media_container--vUhRc";
export var no_item_overlay = "index-module--no_item_overlay--71NCF";
export var no_item_text_container = "index-module--no_item_text_container--3RDMh";
export var non_image_container = "index-module--non_image_container--pp6OA";
export var non_image_display_image = "index-module--non_image_display_image--z+wmb";
export var non_image_display_image_overlay = "index-module--non_image_display_image_overlay--hokb2";
export var non_image_no_display = "index-module--non_image_no_display--u28dM";
export var populated = "index-module--populated--FPB+U";
export var property_container = "index-module--property_container--NmtYn";
export var property_list_item = "index-module--property_list_item--W3zJ5";
export var tag_container = "index-module--tag_container--poZn+";
export var title = "index-module--title--8Hd-p";
export var uploadedContainer = "index-module--uploadedContainer--aiz58";