import React from "react";
import { Link } from "gatsby";
import * as styles from "./collectionBox.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default class CollectionBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    onClick = () => {
        this.props.onSelected && this.props.onSelected(this.props.id);
    };
    render() {
        return (
            <div
                className={`
                ${styles.container}
                ${this.props.selected ? styles.selected : ""} 
                ${this.props.empty ? styles.empty : ""}
            `}
                onClick={this.onClick}
            >
                <div className={styles.image_container}>
                    {this.props.image && <img src={this.props.image} />}
                    <div className={styles.image_overlay}>
                        <p>
                            {this.props.name}
                            {this.props.selected && (
                                <span>
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faCheck} />
                                </span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
